/** @format */

.popUp {
  flex-direction: column;
  border-radius: 1rem 1rem 1rem 0;
  width: 20vw;
  bottom: 100%;
  left: 100%;
  z-index: 500;
  margin: 0 0 0 -0.5rem;
}

.popUp:hover {
  cursor: default;
}

.popUpButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem auto 0.5rem auto;
}

.options {
  padding: 0.5rem;
  margin: 0 0.5rem 0 0.5rem;
}

.clearTable {
  position: relative;
  margin: 0 auto 1rem auto;
}

@media screen and (min-width: 58.1rem) and (max-width: 63rem) {
}

@media screen and (min-width: 45.1rem) and (max-width: 58rem) {
}

@media screen and (min-width: 40.1rem) and (max-width: 45rem) {
  .popUp {
    width: 20vw;
    bottom: 100%;
    left: 0;
    margin: 0;
  }
}

@media screen and (min-width: 26.1rem) and (max-width: 40rem) {
  .popUp {
    width: 30vw;
    bottom: 100%;
    left: 0;
    margin: 0;
  }
}

@media screen and (min-width: 16.6rem) and (max-width: 26rem) {
  .popUp {
    width: 40vw;
    bottom: 100%;
    left: 0;
    margin: 0;
  }
}

@media screen and (max-width: 16.5rem) {
  .popUp {
    width: 20vw;
    bottom: 100%;
    left: 0;
    margin: 0;
  }
}

@media screen and (min-height: 27.1rem) and (max-height: 34rem) {
}

@media screen and (max-height: 27rem) {
}
