/** @format */

.homeTitle {
  font-size: 1.8rem;
  font-weight: bold;
  margin: auto 2rem 2rem 2rem;
}

.homeDialog {
  background-color: white;
  border-radius: 1rem 1rem 1rem 1rem;
}

.homeContent {
  display: flex;
  flex-direction: column;
  margin: 2rem auto 2rem auto;
}

.inputCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto auto auto 0;
  padding: 0 0 1rem 5rem;
}

.inputAdapt {
  display: flex;
  flex-direction: row;
}

.callLabel {
  display: flex;
}

.radioAdapt {
  align-items: center;
}

.radioButton {
  display: flex;
  flex-direction: row;
}

.radioButtonGroup {
  display: flex;
  flex-direction: row;
}

.textField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.formFields {
  margin: 1.5rem 0 1.5rem 0;
  max-width: 8vw;
}

.demo {
  font-size: 1rem;
  padding: 1rem auto auto auto;
}

.note {
  font-size: 1rem;
  margin: 1rem 2rem auto auto;
}

.homeBG {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(166, 166, 166, 0.6);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1001;
}

@media screen and (min-width: 43.1rem) and (max-width: 47rem) {
  .inputAdapt {
    align-items: flex-start;
    flex-direction: column;
  }
  .callWidth {
    max-width: 10rem;
  }
  .demo {
    margin: auto 1rem auto auto;
    max-width: 10rem;
  }
  .homeTitle {
    font-size: 1.7rem;
  }
}

@media screen and (min-width: 41.1rem) and (max-width: 43rem) {
  .inputAdapt {
    align-items: flex-start;
    flex-direction: column;
  }
  .callWidth {
    max-width: 10rem;
  }
  .demo {
    margin: auto 1rem auto auto;
    width: 10rem;
  }
  .homeTitle {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 39.1rem) and (max-width: 41rem) {
  .inputAdapt {
    align-items: flex-start;
    flex-direction: column;
  }
  .callWidth {
    max-width: 8rem;
  }
  .demo {
    margin: auto 1rem auto auto;
    width: 8rem;
  }
  .homeTitle {
    font-size: 1.6rem;
  }

  .homeDialog {
    width: 45%;
  }
}

@media screen and (min-width: 33.1rem) and (max-width: 39rem) {
  .inputAdapt {
    align-items: flex-start;
    flex-direction: column;
  }
  .callWidth {
    max-width: 8rem;
  }
  .demo {
    margin: auto 1rem auto auto;
    width: 8rem;
  }
  .homeTitle {
    font-size: 1.6rem;
  }
  .radioButtonGroup {
    flex-direction: column;
  }
  .radioAdapt {
    align-items: flex-start;
  }
  .inputCont {
    padding: 0 0 1rem 3rem;
  }

  .homeDialog {
    width: 50%;
  }
}

@media screen and (min-width: 29.5rem) and (max-width: 33rem) {
  .inputAdapt {
    align-items: flex-start;
    flex-direction: column;
  }
  .callWidth {
    max-width: 8rem;
  }
  .demo {
    margin: auto 1rem auto auto;
    width: 8rem;
  }
  .homeTitle {
    font-size: 1.6rem;
  }
  .radioButtonGroup {
    flex-direction: column;
  }
  .radioAdapt {
    align-items: flex-start;
  }
  .inputCont {
    font-size: 1.4rem;
    padding: 0 0 1rem 2rem;
  }

  .homeDialog {
    width: 55%;
  }
}

@media screen and (min-width: 23.8rem) and (max-width: 29.4rem) {
  .inputAdapt {
    align-items: flex-start;
    flex-direction: column;
  }
  .callWidth {
    max-width: 8rem;
  }
  .demo {
    margin: auto 1rem auto auto;
    width: 8rem;
  }
  .homeTitle {
    font-size: 1.5rem;
  }
  .radioButtonGroup {
    flex-direction: column;
  }
  .radioAdapt {
    align-items: flex-start;
  }
  .inputCont {
    font-size: 1.4rem;
    padding: 0 0 1rem 2rem;
  }

  .homeDialog {
    width: 65%;
  }
}

@media screen and (max-width: 23.7rem) {
  .inputAdapt {
    align-items: flex-start;
    flex-direction: column;
  }
  .callWidth {
    max-width: 8rem;
  }
  .demo {
    margin: auto 1rem auto auto;
    width: 8rem;
  }
  .homeTitle {
    font-size: 1.5rem;
  }
  .radioButtonGroup {
    flex-direction: column;
  }
  .radioAdapt {
    align-items: flex-start;
  }
  .inputCont {
    font-size: 1.4rem;
    padding: 0 0 1rem 2rem;
  }

  .homeDialog {
    width: 75%;
  }
}

/*@media screen and (min-width: 32.1rem) and (max-width: 50rem){*/
