/** @format */

.extraDetails {
  white-space: normal;
  overflow-wrap: break-word;
  width: 100%;
}

.markerInfo {
  position: absolute;
  background-color: var(--infoBarColor);
  border-radius: 0 1.5rem 1.5rem 1.5rem;
  width: 10vw;
  top: 0;
  left: 0;
  bottom: auto;
  right: auto;
  margin: 4rem 0 0 0;
  padding: 1rem;
  box-shadow: 0.2rem 0.2rem 0.5rem var(--shadowColor);
  z-index: 401;
}

.extraHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0 0.5rem 0;
  font-weight: bold;
  font-size: 1.5rem;
}

.extraRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  margin: 0 0 0.5rem 0;
}

.flagIcon {
  margin: 0 1rem 0 0.5rem;
}

@media screen and (min-width: 42.1rem) and (max-width: 63rem) {
  .markerInfo {
    width: 15vw;
    left: 2rem;
    margin: 4rem 0 0 0;
    padding: 1rem;
  }
}

@media screen and (min-width: 29.1rem) and (max-width: 42rem) {
  .markerInfo {
    width: 22vw;
    left: 2rem;
    margin: 4rem 0 0 0;
    padding: 1rem;
  }
}

@media screen and (min-width: 16.1rem) and (max-width: 29rem) {
  .markerInfo {
    width: 40vw;
    left: 2rem;
    margin: 4rem 0 0 0;
    padding: 1rem;
  }
}

@media screen and (max-width: 16rem) {
  .markerInfo {
    width: 60vw;
    left: 2rem;
    margin: 4rem 0 0 0;
    padding: 1rem;
  }
}

@media screen and (max-height: 27rem) {
  .extraHeader {
    font-size: 1.3rem;
  }

  .extraDetails {
    font-size: 1rem;
  }

  .markerInfo {
    padding: 0.5rem;
  }
}
