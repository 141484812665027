/** @format */

.activeRow {
  text-decoration: none;
  cursor: pointer;
}

.activeRowNoHover {
  text-decoration: none;
}

.activeRow:hover {
  text-decoration: underline;
  color: var(--activeRow);
}

.infoCells {
  position: relative;
  text-align: left;
  padding: 1rem 1rem 1rem 4rem;
}

.editSubmit {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  padding: 0.5rem 0.5rem 0.5rem 0;
  margin: 1rem 1rem 1rem 4rem;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
}
/*style={{ display: "flex", flexDirection: "row", borderStyle: "solid," }}*/

.buttonCell {
  position: relative;
  text-align: left;
  padding: 1rem 1rem 1rem 1rem;
}

.submitButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-style: none;
  background-color: transparent;
  padding: 0;
}

.submitButton:hover {
  cursor: pointer;
}

@media screen and (min-width: 32.1rem) and (max-width: 50rem) {
  .infoCells {
    font-size: 1.4rem;
    padding: 1rem 1rem 1rem 1rem;
  }
}

@media screen and (min-width: 29.1rem) and (max-width: 32rem) {
  .infoCells {
    font-size: 1.2rem;
    padding: 1rem 1rem 1rem 1rem;
  }
}

@media screen and (min-width: 22.1rem) and (max-width: 29rem) {
  .infoCells {
    font-size: 1.1rem;
    padding: 1rem 0.5rem 1rem 0.5rem;
  }
}

@media screen and (min-width: 16.1rem) and (max-width: 22rem) {
  .infoCells {
    font-size: 1.1rem;
    padding: 1rem 0.3rem 1rem 0.3rem;
  }
}

@media screen and (max-width: 16rem) {
  .infoHead {
    font-size: 1rem;
    padding: 1rem 0.3rem 1rem 0.3rem;
  }
}

@media screen and (min-height: 27.1rem) and (max-height: 34rem) {
  .infoCells {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

@media screen and (max-height: 27rem) {
  .infoCells {
    font-size: 1.3rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
