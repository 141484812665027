/** @format */

:root {
  --pageBg: #1d3557; /*#62b6cb;*/
  --mainContainerBg: #edf6f9; /*#bee9e8;*/
  --infoBarColor: white;
  --infoHeaderColor: #dddddd;
  --activeRow: #22577a;
  --shadowColor: #a6a6a6;
}

html {
  font-size: 10px; /*change default font size so 1rem = 10px rather than 16*/
}

body {
  font-family: "Roboto", sans-serif;
  background-color: var(--pageBg);
}

.title {
  display: flex;
  flex-direction: row;
  max-width: 100rem;
  margin: 0 auto 0 auto;
}

.logo {
  height: 6vh;
  margin: 1rem 0 1rem 2rem;
}

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--mainContainerBg);
  border-radius: 2rem;
  overflow: hidden;
  max-width: 100rem;
  min-height: 80vh;
  margin: 0 auto 0.5rem auto;
  font-size: 1.6rem; /*change font size to 1.6rem to equal browser default of 16px*/
}

.stationInfo {
  width: 100%;
  height: 40%;
}

.extraCells {
  text-align: left;
}

.callInput {
  width: 100%;
}

.footer {
  text-align: center;
  max-width: 100rem;
  margin: 0 auto 0 auto;
  color: white;
}

.routeMain {
  margin: 0 5rem 0 5rem;
}

.logoutLogo {
  width: auto;
  height: 2.5rem;
  margin: auto 2rem 2rem 1.5rem;
}

.logoutLogo:hover {
  cursor: pointer;
}
.logoutBar {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
}

/*.menuBar {
  display: flex;
  flex-direction: row;
  margin: auto 0 2rem auto;
}

.menuEl {
  margin: auto 2rem 0 2rem;
  font-size: 1.6rem;
}*/

a:link {
  color: #ffba08;
}

a:visited {
  color: #f48c06;
}

@media screen and (min-width: 42.1rem) and (max-width: 63rem) {
  .logo {
    height: 4vh;
  }
}

@media screen and (min-width: 29.1rem) and (max-width: 42rem) {
  .logo {
    height: 4vh;
  }
}

@media screen and (min-width: 16.1rem) and (max-width: 29rem) {
  .logo {
    height: 4vh;
  }
}

@media screen and (max-width: 16rem) {
  .logo {
    height: 4vh;
  }
}

@media screen and (max-height: 27rem) {
  .logo {
    height: 10vh;
    margin: 1rem 0 2rem 0;
  }
}

/*@media screen and (min-width: 42.1rem) and (max-width: 63rem){}

@media screen and (min-width: 29.1rem) and (max-width: 42rem){}

@media screen and (min-width: 16.1rem) and (max-width: 29rem){}

@media screen and (max-width: 16rem){}

@media screen and (min-height: 27.1rem) and (max-height: 34rem){}

@media screen and (max-height: 27rem){}*/
