/** @format */

.settings {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto 2rem 2rem 2rem;
  height: 2.5rem;
  width: 2.5rem;
}

.settingsIcon {
  height: inherit;
  width: inherit;
}

.settingsIcon:hover {
  cursor: pointer;
}

.settingsHead {
  font-size: 1.6rem;
  text-decoration: underline;
  margin: 1rem 2rem 1rem 2rem;
  white-space: nowrap;
}

.settingsEl {
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  margin: 0.5rem 2rem 0.5rem 3rem;
  white-space: nowrap;
}

.settingsLink {
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  margin: 0.5rem 2rem 0.5rem 3.5rem;
  white-space: nowrap;
}

.menu {
  flex-direction: column;
  border-radius: 1rem 0 1rem 1rem;
  background-color: var(--infoBarColor);
  box-shadow: 0.2rem 0.2rem 0.5rem var(--shadowColor);
  padding: 0.5rem;
  top: 100%;
  right: 100%;
  z-index: 1100;
}

.settingsLink:hover {
  text-decoration: underline;
  cursor: pointer;
}
