/** @format */

.map {
  position: relative;
  width: 100%;
  height: 53vh;
}

.controlBar {
  display: flex;
  flex-direction: row;
}

.tutorial {
  font-size: 1.4rem;
}

@media screen and (min-width: 42.1rem) and (max-width: 63rem) {
}

@media screen and (min-width: 29.1rem) and (max-width: 42rem) {
}

@media screen and (max-width: 29rem) {
}

@media screen and (min-height: 27.1rem) and (max-height: 34rem) {
}

@media screen and (max-height: 27rem) {
}
