/** @format */

.flagStatImg {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem 0 0.5rem 0;
}

.pageRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2rem auto 4rem auto;
}

.statCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
  box-shadow: 0.2rem 0.2rem 0.5rem var(--shadowColor);
  background-color: var(--infoBarColor);
  overflow-y: auto;
  margin: 0 2rem auto 2rem;
}

.statTable {
  border-collapse: collapse;
  margin: 0 0 0 0;
}

.statHead {
  background-color: var(--infoHeaderColor);
  text-align: center;
  padding: 1rem 1rem 1rem 1rem;
}

.statCells {
  text-align: center;
  padding: 1rem 2rem 1rem 2rem;
}

/*@media screen and (min-width: 42.1rem) and (max-width: 55rem){}

    @media screen and (min-width: 29.1rem) and (max-width: 42rem){}

    @media screen and (min-width: 16.1rem) and (max-width: 29rem){}

    @media screen and (max-width: 16rem){}

    @media screen and (min-height: 27.1rem) and (max-height: 34rem){}

    @media screen and (max-height: 27rem){}*/
